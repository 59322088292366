import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useMedia } from "./utils/hooks";
import { isVisible } from "./utils/functions";
import $ from "jquery";

import "./bootstrap.css";
import "./App.scss";

import Header from "./components/_Header";
import Footer from "./components/_Footer";
import SideBox from "./components/_SideBox";
import NotFound from "./components/NotFound";
import About from "./components/About";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Education from "./components/Education";
import Portfolio from "./components/Portfolio";
import References from "./components/References";

export default function App() {
    const [profile, setProfile] = useState(null);
    const isMobile = useMedia(["(max-width: 992px)"], [true], false);
    const offset = isMobile ? 0 : 75;

    useEffect(() => {
        fetch("/profile.json")
            .then((response) => response.json())
            .then((data) => setProfile(data))
            .catch((error) => console.error(error));
    }, [setProfile]);

    useEffect(() => {
        // on clicking the nav link, take user to section
        $(`a[href*='#']`).on("click", function (e) {
            e.preventDefault();
            const target = $(this).attr("href").split("#")[1];
            $("body, html").animate({ scrollTop: $(`[aria-label=${target}]`).position().top - offset }, "slow");
        });

        const handler = (e) => {
            // add active to the element when you scroll
            $(".box").each(function () {
                if (isVisible($(this), offset + 50)) {
                    const target = $(this).attr("aria-label");
                    $("header nav ul li").removeClass("active");
                    $("header nav ul li a").filter(`[href$=${target}]`).parent().addClass("active");
                }
            });
        };

        handler();
        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, [profile, offset]);

    return profile ? (
        <div className="App">
            <Header {...profile} />
            <main className="container">
                <SideBox {...profile} />
                <Router>
                    <Route component={HomePage} exact path="/" />
                    <Route component={Error404} exact path="/:page" />
                </Router>
            </main>
            <Footer />

            <svg className="d-none d-md-block bgShape1" height="519" width="758">
                <polygon points="0,455,693,352,173,0,92,0,0,71" />
            </svg>

            <svg className="d-none d-md-block bgShape2" height="536" width="633">
                <polygon points="0,0,633,0,633,536" />
            </svg>
        </div>
    ) : (
        <div className="App" />
    );

    function Error404() {
        return (
            <div className="mainBox">
                <NotFound />
            </div>
        );
    }

    function HomePage() {
        return (
            <div className="mainBox">
                <About data={profile.about} lang={profile.languages} />
                <Skills data={profile.skills} />
                <Experience data={profile.experience} />
                <Education data={profile.education} />
                <Portfolio data={profile.portfolio} />
                <References data={profile.reference} />
                <Contact config={profile.config} social={profile.social} gmap={profile.about.gmap} />
            </div>
        );
    }
}
