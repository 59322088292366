import React, { useState, useEffect, useCallback } from "react";
import { animated, useTransition } from "react-spring";
import { isEmpty, shuffleArray } from "../utils/functions";
import { useMedia, useMeasure } from "../utils/hooks";
import $ from "jquery";

export default function Portfolio({ data }) {
    const [items, setItems] = useState(isEmpty(data.items) ? [] : data.items);
    const [filter, setFilter] = useState("*");
    const [bindRef, { width }] = useMeasure();
    const columns = useMedia(["(max-width: 768px)", "(max-width: 992px)", "(max-width: 1170px)"], [1, 2, 1], 2);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        setFilter(e.target.value);
    }, []);

    let heights = new Array(columns).fill(0);

    let gridItems = items.map((child, i) => {
        child.key = i;
        if (child.hide) return { ...child, xy: [0, 0], width: 0, height: 0 };
        // Basic masonry-grid placing, puts tile into the smallest column using Math.min
        const column = heights.indexOf(Math.min(...heights));
        const height = ((width / columns) * 2) / 3;
        // X = container width / number of columns * column index
        // Y = it's just the height of the current column
        const xy = [(width / columns) * column, (heights[column] += height) - height];
        return { ...child, xy, width: width / columns, height: height };
    });

    // Turn the static grid values into animated transitions, any addition, removal or change will be animated
    const transitions = useTransition(gridItems, (item) => item.key, {
        from: ({ xy, width, height }) => ({ xy, width, height, opacity: 0 }),
        enter: ({ xy, width, height }) => ({ xy, width, height, opacity: 1 }),
        update: ({ xy, width, height }) => ({ xy, width, height }),
        leave: { xy: [0, 0], width: 0, height: 0, opacity: 0 },
        trail: 10,
    });

    useEffect(() => {
        $(".nav-pills button").addClass("btn-secondary").removeClass("btn-dark");
        $(`.nav-pills button[value='${filter}']`).addClass("btn-dark");
        setItems((items) =>
            shuffleArray(items).map((item) => {
                if (filter === "*" || item.tags.indexOf(filter) !== -1) {
                    return { ...item, hide: false };
                }
                return { ...item, hide: true };
            })
        );
    }, [filter]);

    return (
        <div className="box" aria-label="portfolio">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">{data.label}</h2>

                    <ul className="nav nav-pills">
                        <li className="nav-item my-1">
                            <button className="nav-link btn btn-sm" value="*" onClick={handleClick}>
                                all
                            </button>
                        </li>
                        {data.hastags.split(",").map((tag, i) => {
                            return (
                                <li key={i} className="nav-item my-1 ml-2">
                                    <button className="nav-link btn btn-sm" value={tag} onClick={handleClick}>
                                        {tag}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>

                    <div {...bindRef} className="mt-2 gridItems" style={{ height: Math.max(...heights) }}>
                        {transitions.map(({ item, props: { s, xy, ...rest }, key }) => (
                            <animated.div key={key} className="gridItem" style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`), ...rest }}>
                                <div className="gridBody" style={{ backgroundImage: `url(${item.image})` }}>
                                    <figure className="gridImage">
                                        <img className="img-fluid" src={item.image} alt={item.label} />
                                    </figure>

                                    <div className="text-center gridCaption">
                                        <h2 className="captionTitle adapt">{item.label}</h2>
                                        <p className="captionText">{item.brief}</p>
                                        <a className="captionBtn btn btn-sm btn-primary" href={item.link} target="_blank" rel="noopener noreferrer">
                                            View More
                                        </a>
                                    </div>
                                </div>
                            </animated.div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
}
