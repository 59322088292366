import React from "react";
import { isEmpty } from "../utils/functions";

export default function Education({ data }) {
    if (isEmpty(data.items)) return "";

    return (
        <div className="box" aria-label="education">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">{data.label}</h2>
                    <div className="timeline">
                        {data.items.map((itm, idx) => {
                            return (
                                <div key={idx} className="timelineBox">
                                    <time className="timelineDate fontRegular">{itm.duration}</time>
                                    <h3>{itm.course}</h3>
                                    <p className="mb-2 opacity60">{itm.academy}</p>
                                    <p className="fontSmall">{itm.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </div>
        </div>
    );
}
