export const emailPlain = ({ email, author, comment }) => {
    return ["Name: " + author, "Email: " + email, "Comment: " + comment].join("\n");
};

export const emailHtml = ({ email, author, comment }) => {
    return `<!DOCTYPE html>
    <html lang="en">
        <head>
            <meta name="format-detection" content="email=no" />
            <meta name="format-detection" content="date=no" />
        </head>
    
        <body style="margin: 0; padding: 0" bgcolor="#FAFAFA">
            <table width="100%" height="100%" style="min-width: 320px" border="0" cellspacing="0" cellpadding="0" lang="en">
                <tr>
                    <td height="32" style="height: 32px"></td>
                </tr>
                <tr align="center">
                    <td>
                        <div itemscope itemtype="//schema.org/EmailMessage">
                            <div itemprop="action" itemscope itemtype="//schema.org/ViewAction">
                                <link itemprop="url" href="" />
                                <meta itemprop="name" content="" />
                            </div>
                        </div>
                        <table border="0" cellspacing="0" cellpadding="0" width="100%" style="max-width: 480px; min-width: 240px">
                            <tr>
                                <td width="8" style="width: 8px"></td>
                                <td>
                                    <div style="border-style: solid; border-width: thin; border-color: #dadce0; border-radius: 8px; padding: 40px 20px; background-color: #ffffff">
                                        <div style="font-family: Helvetica, Arial, sans-serif; border-bottom: thin solid #dadce0; color: rgba(0, 0, 0, 0.8); line-height: 32px; padding-bottom: 24px; text-align: center; word-break: break-word">
                                            <table align="center" width="100%" style="margin-top: 8px">
                                                <tr style="line-height: normal">
                                                    <td align="center"><div style="font-size: 20px">${author}</div></td>
                                                </tr>
                                                <tr style="line-height: normal">
                                                    <td align="center"><a style="font-family: Helvetica, Arial, sans-serif; color: rgba(0, 0, 0, 0.87); font-size: 14px; line-height: 20px">${email}</a></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div style="font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: rgba(0, 0, 0, 0.87); line-height: 20px; padding-top: 24px; text-align: left">${comment}</div>
                                    </div>
                                </td>
                                <td width="8" style="width: 8px"></td>
                            </tr>
                        </table>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="max-width: 480px; min-width: 240px; padding-top: 10px; padding-bottom: 20px">
                            <tr style="line-height: normal">
                                <td align="center"><div style="font-family: Helvetica, Arial, sans-serif; color: #555555; font-size: 12px; line-height: 18px; text-align: center">You are receiving this message as one of your known recently filled it for you.</div></td>
                            </tr>
                            <tr style="line-height: normal">
                                <td align="center">
                                    <div style="font-family: Helvetica, Arial, sans-serif; color: #555555; font-size: 12px; line-height: 18px; text-align: center">&copy;&nbsp;<a style="font-family: Helvetica, Arial, sans-serif; color: #555555; line-height: 18px; text-decoration: none" href="https://just-smtp.appgo.in">Just-SMTP</a>&nbsp;2021&nbsp; &bull; &nbsp;<a style="font-family: Helvetica, Arial, sans-serif; color: #999999; line-height: 18px; text-decoration: none" href="https://just-smtp.appgo.in/unsubscribe/${email}">Opt-out</a>&nbsp; &bull; &nbsp;All Rights Reserved.</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height="32" style="height: 32px"></td>
                </tr>
            </table>
        </body>
    </html>`.replace(/\s+</g, "<");
};
