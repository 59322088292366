export const isArray = (data) => Array.isArray(data);
export const isString = (data) => typeof data === "string";
export const isNumber = (data) => typeof data === "number";
export const isBoolean = (data) => data === false || data === true;
export const isObject = (data) => String(data) === "[object Object]";
export const isUndefined = (data) => data === undefined;
export const isNull = (data) => data === null;
export const isJson = (data) => {
    try {
        JSON.parse(data);
    } catch (e) {
        return false;
    }
    return true;
};

export const isEmpty = (val) => {
    if (isNull(val) || isUndefined(val)) return true;
    if (isArray(val)) return val.length === 0;
    if (isObject(val)) return Object.keys(val).length === 0;
    if (isString(val)) return val.length === 0;
    if (isNumber(val)) return val === 0;
    if (!val) return true;
    return false;
};

export const isVisible = (elm, topSet = 0) => {
    elm = typeof elm == "object" || typeof elm == "function" ? elm[0] : elm;
    const rect = elm.getBoundingClientRect();
    return rect.bottom >= 0 && rect.right >= 0 && rect.top + topSet <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth);
};

export const shuffleArray = (array) => {
    let currentIndex = array.length;
    let randomIndex, temporaryValue;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const Cookie = {
    get: function (name) {
        name = name + "=";
        const ca = decodeURIComponent(document.cookie).split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    set: function (name, value, exhrs = 1) {
        const d = new Date();
        d.setTime(d.getTime() + exhrs * 60 * 60 * 1000);
        document.cookie = name.concat("=", value, ";expires=", d.toUTCString(), "; path=/");
    },
};

export const LocalStorage = {
    get: (key, val = "") => {
        const data = window.localStorage.getItem(key) || val;
        return isJson(data) ? JSON.parse(data) : data;
    },
    set: (key, val = "") => {
        if (isArray(val) || isObject(val)) val = JSON.stringify(val);
        return window.localStorage.setItem(key, val) || val;
    },
    del: (key, val = "") => window.localStorage.removeItem(key) || val,
};
