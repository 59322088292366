import React from "react";

export default function NotFoundBox() {
    return (
        <div className="box">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">404, Not Found</h2>
                    <p>
                        <b>Hi Buddy,</b>
                    </p>
                    <p>
                        I'm unable to deliver the stuff which you are looking for. But, you aren't at the wrong place. I mean to say, you can give a rest to your mind by deploying yourself to this super game by
                        <a className="ml-2" href="https://www.google.com/doodles" target="_blank" rel="noopener noreferrer">
                            Google
                        </a>
                        .
                    </p>
                    <div className="iframe-wrap">
                        <iframe src="https://www.google.com/logos/fnbx/solitaire/standalone.3.html" title="Solitaire"></iframe>
                    </div>
                </section>
            </div>
        </div>
    );
}
