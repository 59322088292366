import React, { useCallback } from "react";

export default function About({ data, lang }) {
    const renderInfo = useCallback((about) => {
        const keys = ["fullName", "birthDate", "email", "phoneNo", "address"];

        return (
            <div>
                <h3 className="title">Personal Information</h3>
                {keys
                    .filter((k) => about.hasOwnProperty(k))
                    .map((itm, idx) => {
                        return (
                            <dl key={idx}>
                                <dt className="fontRegular opacity90">{itm.replace(/[A-Z]/g, (u) => ` ${u}`).replace(/\b\w/, (f) => f.toUpperCase())}</dt>
                                <dd className="fontRegular opacity80">{about[itm]}</dd>
                            </dl>
                        );
                    })}
            </div>
        );
    }, []);

    const renderLang = useCallback((languages) => {
        if (!languages.items || !languages.items.length) return "";
        return (
            <div>
                <h3 className="title">{languages.label}</h3>
                {languages.items.map((itm, idx) => {
                    return (
                        <div key={idx} className="mb-3" aria-valuenow={itm.amount / 10}>
                            <strong className="d-block fontRegular opacity80">{itm.title}</strong>
                            <span className="progressBullet">
                                <span className={`fadeIn delay-0 bullet ${itm.amount > 0 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-1 bullet ${itm.amount > 10 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-2 bullet ${itm.amount > 20 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-3 bullet ${itm.amount > 30 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-4 bullet ${itm.amount > 40 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-5 bullet ${itm.amount > 50 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-6 bullet ${itm.amount > 60 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-7 bullet ${itm.amount > 70 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-8 bullet ${itm.amount > 80 ? "fill" : ""}`}></span>
                                <span className={`fadeIn delay-9 bullet ${itm.amount > 90 ? "fill" : ""}`}></span>
                            </span>
                            <span className="ml-2 fontRegular opacity90">{itm.level}</span>
                        </div>
                    );
                })}
            </div>
        );
    }, []);

    return (
        <div className="box" aria-label="about">
            <div className="boxBody">
                <section className="section">
                    <div className="row">
                        <div className="col">
                            <h2 className="title">{data.label}</h2>
                            <p className="font-weight-bold">Hello, I'm {data.fullName}!</p>
                            <p className="text-justify">{data.description}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="ml-auto">
                            <img className="signature adapt" src={data.signature} alt="signature" />
                        </div>
                    </div>
                </section>

                <div className="divider adapt"></div>

                <section className="section">
                    <div className="row">
                        <div className="col-12 col-md-6 my-2">{renderInfo(data)}</div>
                        <div className="col-12 col-md-6 my-2">{renderLang(lang)}</div>
                    </div>
                </section>
            </div>
        </div>
    );
}
