import React from "react";
import { isEmpty } from "../utils/functions";

export default function Reference({ data }) {
    if (isEmpty(data.items)) return "";

    return (
        <div className="box" aria-label="reference">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">{data.label}</h2>

                    <div className="row">
                        {data.items.map((itm, idx) => {
                            return (
                                <div key={idx} className="col-12">
                                    <div className="d-flex refBox">
                                        <img className="rounded-circle" style={{ width: 48, height: 48, marginRight: 24 }} alt={itm.name} src={itm.avatar} />
                                        <div>
                                            <strong className="d-block fontRegular">{itm.name}</strong>
                                            <div className="fontRegular opacity75">{itm.brief}</div>
                                            <p className="mt-2 fontRegular fontXS">{itm.remarks}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </div>
        </div>
    );
}
