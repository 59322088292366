import React from "react";

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="text-center mainBox">
                    <p className="fontRegular">Copyrights &copy;{new Date().getFullYear()}. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
}
