import React, { useState, useEffect } from "react";
import { animated, config, useTransition } from "react-spring";
import { useVisible } from "../utils/hooks";
import { isEmpty } from "../utils/functions";

export default function SkillBox({ data }) {
    const [items, setItems] = useState([]);
    const [bindRef, isVisible] = useVisible(50);

    const transitions = useTransition(items, (i) => i.title, {
        from: { a: 0 },
        enter: ({ a }) => ({ a }),
        update: ({ a }) => ({ a }),
        leave: { a: 0 },
        config: config.gentle,
    });

    useEffect(() => {
        setItems(data.items.map((item) => (isVisible ? { ...item, a: parseInt(item.amount) } : { ...item, a: 0 })));
    }, [data, isVisible]);

    if (isEmpty(data.items)) return "";
    return (
        <div {...bindRef} className="box" aria-label="skills">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">{data.label}</h2>

                    <div className="row">
                        {transitions.map(({ item, props: { a }, key }) => (
                            <div key={key} className="col-12 col-md-6">
                                <div className="progressLine">
                                    <strong className="fontRegular opacity80">{item.title}</strong>
                                    <animated.span className="progressText fontRegular">{a.interpolate((a) => a.toFixed(0).concat("%"))}</animated.span>
                                    <div className="mt-1 progressBar">
                                        <animated.div className="fill" style={{ width: a.interpolate((a) => `${a}%`) }}></animated.div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
}
