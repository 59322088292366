import React, { useState, useCallback } from "react";
import { useSnackbar } from "react-simple-snackbar";
import SocialBox from "./_SocialLinks";
import * as template from "../utils/template";

const options = {
    position: "bottom-left",
    style: {},
    closeStyle: { display: "none" },
};

const _trim = (val) => String(val).replace(/^[\s]+|[\s]$/g, "");
const validate = {
    email: (val) => /^[\S]+@[a-z0-9-]+\.[a-z0-9]{2,}$/i.test(val),
    input: (val) => /^.{3,}$/i.test(val),
};

const isValid = (key, val = "") => {
    val = _trim(val);
    return !(key === "email" ? validate.email(val) : validate.input(val));
};

export default function Contact({ config, social, gmap }) {
    const [thankSnackbar] = useSnackbar({ ...options, style: { marginLeft: 8, backgroundColor: "seagreen" } });
    const [sorrySnackbar] = useSnackbar({ ...options, style: { marginLeft: 8, backgroundColor: "crimson" } });
    const [email, setEmail] = useState("");
    const [author, setAuthor] = useState("");
    const [comment, setComment] = useState("");
    const [isProcessing, setProcessing] = useState(false);

    const [canSendEmail, setSendEmail] = useState(config.justSmtp && Object.keys(config.justSmtp).length && config.justSmtp.authorization && config.justSmtp.emailTo);

    const handleChange = useCallback((e) => {
        if (e.target.id === "email") setEmail(e.target.value);
        if (e.target.id === "author") setAuthor(e.target.value);
        if (e.target.id === "comment") setComment(e.target.value);
        isValid(e.target.id, e.target.value) ? e.target.classList.add("danger") : e.target.classList.remove("danger");
    }, []);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let hasErrors = true;
            ["email", "author", "comment"].forEach((k) => {
                const hasError = isValid(k, eval(k));
                const selector = e.target.querySelector("#" + k);
                hasError ? selector.classList.add("danger") : selector.classList.remove("danger");
                hasErrors &= hasError;
            });

            if (!canSendEmail) {
                console.warn("JustSMTP:", "please configure this application first!");
            } else if (!hasErrors) {
                setProcessing(true);

                fetch("https://just-smtp.appgo.in/v1/sendEmail", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: config.justSmtp.authorization,
                    },
                    body: JSON.stringify({
                        to: config.justSmtp.emailTo,
                        subject: "Contact Form Response",
                        text: template.emailPlain({ email, author, comment }),
                        html: template.emailHtml({ email, author, comment }),
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                        setTimeout(() => {
                            setProcessing(false);
                        }, 0);

                        if (!data.status || data.status !== 200) {
                            sorrySnackbar(data.message || "Invalid Request", 30e3);
                            throw new Error("Invalid Request");
                        } else {
                            setEmail("") || setAuthor("") || setComment("");
                            thankSnackbar(data.message, 10e3);
                            return;
                        }
                    })
                    .catch((error) => console.error(error));
            }
        },
        [email, author, comment, setProcessing, config, sorrySnackbar, thankSnackbar]
    );

    return (
        <div className="box" aria-label="contact">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">Contact Me</h2>

                    <div className="row mx-md-5">
                        <div className="col-12">
                            <SocialBox social={social} />
                            <h5 className="my-3 fontRegular opacity80">Fell free to contact me the core of your interest</h5>
                        </div>

                        {canSendEmail && (
                            <div className="col-12 mt-4">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="author">Your Name</label>
                                        <input id="author" className="form-control" type="text" value={author} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Your E-mail</label>
                                        <input id="email" className="form-control" type="email" value={email} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="comment">Your Message</label>
                                        <textarea id="comment" className="form-control" rows="6" value={comment} onChange={handleChange}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary font-weight-bold" disabled={isProcessing} dangerouslySetInnerHTML={{ __html: isProcessing ? `wait... <img src="/assets/puff.svg" />` : "Submit" }} />
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                    <div id="map" className="w-100 overflow-hidden mt-4">
                        <iframe title="map" src={`https://maps.google.com/maps?output=embed&q=${gmap.lat},${gmap.long}&z=${gmap.zoom}`} width="100%" height="400" frameBorder={0} allowFullScreen={true} style={{ width: "100%", border: 0 }}></iframe>
                    </div>
                </section>
            </div>
        </div>
    );
}
