import { useState, useEffect, useRef, useCallback } from "react";

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => void (ref.current = value), [value]);
    return ref.current;
};

export const useMedia = (queries, values, defaultValue) => {
    const match = useCallback(() => {
        const qIndex = queries.findIndex((q) => matchMedia(q).matches);
        return values[qIndex] || defaultValue;
    }, [queries, values, defaultValue]);

    const [value, setValue] = useState(match);

    useEffect(() => {
        const handler = () => setValue(match);
        handler();
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, [match]);
    return value;
};

export const useMeasure = () => {
    const ref = useRef();
    const [bounds, setBounds] = useState({ x: 0, y: 0, top: 0, left: 0, rigth: 0, bottom: 0, width: 0, height: 0 });
    useEffect(() => {
        const handler = () => setBounds(ref.current.getBoundingClientRect());
        handler();
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);
    return [{ ref }, bounds];
};

export const useSticky = (topOffset = 0) => {
    const ref = useRef();
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        const handler = () => setSticky(ref.current.getBoundingClientRect().top + topOffset < window.pageYOffset);
        handler();
        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, [topOffset]);

    return [{ ref }, sticky];
};

export const useVisible = (topOffset = 0) => {
    const ref = useRef();
    const [isVisible, setVisibility] = useState(false);

    useEffect(() => {
        const handler = () => {
            const rect = ref.current.getBoundingClientRect();
            setVisibility(rect.bottom >= 0 && rect.right >= 0 && rect.top + topOffset <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
        };
        handler();
        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, [topOffset]);
    return [{ ref }, isVisible];
};
