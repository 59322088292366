import React from "react";
import { isEmpty } from "../utils/functions";

export default function Experience({ data }) {
    if (isEmpty(data.items)) return "";

    return (
        <div className="box" aria-label="experience">
            <div className="boxBody">
                <section className="section">
                    <h2 className="title">{data.label}</h2>

                    <div className="timeline">
                        {data.items.map((itm, idx) => {
                            return (
                                <div key={idx} className="timelineBox">
                                    <time className="timelineDate fontRegular">{itm.duration}</time>
                                    <h3>{itm.title}</h3>
                                    <div className="mb-2">{itm.place}</div>
                                    <div className="mb-2 fontSmall opacity60">
                                        <svg viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="#555" strokeWidth="2">
                                            <circle cx="12" cy="10" r="3" />
                                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                        </svg>
                                        <span className="ml-1">{itm.location}</span>
                                    </div>
                                    <p className="fontSmall">{itm.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </div>
        </div>
    );
}
