import React from "react";
import { animated, config, useSpring } from "react-spring";
import { useMedia, useSticky } from "../utils/hooks";

const svg = {
    size: { width: 32, height: 32 },
    tone1: { fill: "#888", className: "tone1" },
    tone2: { fill: "#444", className: "tone2" },
};

const icon = {
    contact: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="phone-settings">
            <circle r="32" cx="74.553" cy="242.179" {...svg.tone1} />
            <circle r="32" cx="164.853" cy="163.147" {...svg.tone1} />
            <circle r="32" cx="255.153" cy="84.116" {...svg.tone1} />
            <path d="M453.369,129.994,379.407,93.555l-78.3,104.4,39.149,52.2h0a104.4,104.4,0,0,1-104.4,104.4h0l-52.2-39.15L79.26,393.7,115.7,467.663A9.229,9.229,0,0,0,123.524,472h22.345c172.223,0,311.837-139.614,311.837-311.837V137.819A9.227,9.227,0,0,0,453.369,129.994Z" {...svg.tone2} />
        </svg>
    ),
    education: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="school">
            <polygon points="104 271.011 104 374.036 256 459.536 408 374.036 408 271.011 256 355.455 104 271.011" {...svg.tone1} />
            <polygon points="256 36.966 16 161.41 16 190.121 88 230.122 256 323.455 424 230.122 456.149 212.261 456.149 296 496 296 496 161.41 256 36.966" {...svg.tone2} />
        </svg>
    ),
    experience: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="briefcase">
            <path d="M471,112.232H368.394l.049-95.87H154.224l-.05,95.87H41A24.8,24.8,0,0,0,16.232,137v61.776l33.536,33.536L95.456,278H238V232.5h36V278H416.544l45.688-45.688,33.536-33.536V137A24.8,24.8,0,0,0,471,112.232Zm-144.606,0H196.174l.028-53.87h130.22Z" {...svg.tone1} />
            <path d="M431.456,314H274v46H238V314H80.544L49.768,283.224,16.232,249.688V471.033A24.8,24.8,0,0,0,41,495.8H471a24.8,24.8,0,0,0,24.768-24.768V249.688l-33.536,33.536Z" {...svg.tone2} />
        </svg>
    ),
    hobbies: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="flower">
            <path d="M418.857,333.143A162.926,162.926,0,0,0,263.194,448H248.806A162.926,162.926,0,0,0,93.143,333.143H16A162.858,162.858,0,0,0,178.857,496H333.143A162.858,162.858,0,0,0,496,333.143H418.857Z" {...svg.tone1} />
            <path d="M169.227,262.773a87.36,87.36,0,0,0,24.547,47.453l4.687,4.686h6.627A87.354,87.354,0,0,0,256,298.716a87.356,87.356,0,0,0,50.912,16.2h6.627l4.687-4.686a87.36,87.36,0,0,0,24.547-47.453,87.36,87.36,0,0,0,47.453-24.547l4.686-4.687v-6.627A87.354,87.354,0,0,0,378.716,176a87.356,87.356,0,0,0,16.2-50.912v-6.627l-4.686-4.687a87.36,87.36,0,0,0-47.453-24.547,87.36,87.36,0,0,0-24.547-47.453l-4.687-4.686h-6.627A87.356,87.356,0,0,0,256,53.284a87.354,87.354,0,0,0-50.912-16.2h-6.627l-4.687,4.686a87.36,87.36,0,0,0-24.547,47.453,87.36,87.36,0,0,0-47.453,24.547l-4.686,4.687v6.627A87.356,87.356,0,0,0,133.284,176a87.354,87.354,0,0,0-16.2,50.912v6.627l4.686,4.687A87.36,87.36,0,0,0,169.227,262.773ZM256,128a48,48,0,1,1-48,48A48,48,0,0,1,256,128Z" {...svg.tone2} />
        </svg>
    ),
    portfolio: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="laptop">
            <rect width="256" height="143" x="128" y="161" {...svg.tone1} />
            <path d="M464,336V111a30,30,0,0,0-30-30H78a30,30,0,0,0-30,30V336H16v64.05L48,432H464l32-31.95V336Zm-48,0H96V129H416Z" {...svg.tone2} />
        </svg>
    ),
    reference: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="contact-label">
            <polygon points="192 16 192 39.437 344.111 140.845 495.28 42.585 496 43.692 496 16 192 16" {...svg.tone1} />
            <path d="M344.055,174.427a23.975,23.975,0,0,1-13.311-4.034L192,77.9v71.148A104.18,104.18,0,0,1,264,248v8H496V80.283L357.135,170.545A23.976,23.976,0,0,1,344.055,174.427Z" {...svg.tone1} />
            <path d="M205.826,377.435h0L232,312V248h0a72.466,72.466,0,0,0-.83-10.965A71.593,71.593,0,0,0,219.7,207.744q-1.941-2.872-4.145-5.543a72.426,72.426,0,0,0-15.3-13.9q-2.872-1.94-5.936-3.606a72.116,72.116,0,0,0-68.64,0q-3.06,1.662-5.936,3.606a72.426,72.426,0,0,0-15.3,13.905q-2.2,2.667-4.145,5.543A71.593,71.593,0,0,0,88.83,237.035,72.466,72.466,0,0,0,88,248v64l26.174,65.434h0L34.511,421.712a35.874,35.874,0,0,0-4.076,2.639q-.964.721-1.875,1.5a35.908,35.908,0,0,0-3.4,3.338q-.792.887-1.522,1.821A35.988,35.988,0,0,0,16,453.178V496H304V453.178a36,36,0,0,0-7.633-22.166q-.729-.935-1.522-1.821a35.908,35.908,0,0,0-3.405-3.338q-.909-.78-1.875-1.5a35.874,35.874,0,0,0-4.076-2.639Z" {...svg.tone2} />
        </svg>
    ),
    skills: (
        <svg {...svg.size} viewBox="0 0 512 512" aria-label="badge">
            <polygon points="351.365 383.768 307.087 383.679 271.422 403.284 256.066 411.726 240.682 403.334 205.142 383.947 158.99 383.698 147.98 383.638 124.408 496 170.507 496 255.935 450.18 340.401 496 387.705 496 364.166 383.794 351.365 383.768" {...svg.tone1}></polygon>
            <polygon points="415.387 154.414 413.192 106.36 372.673 80.433 346.745 39.913 298.69 37.719 255.978 15.593 213.265 37.719 165.209 39.913 139.282 80.433 98.763 106.36 96.568 154.414 74.471 197.071 95.784 238.858 96.049 289.307 138.925 313.842 159.163 351.698 213.384 351.991 256.007 375.242 298.901 351.662 351.43 351.769 355.821 344.014 355.821 344.014 372.846 313.95 415.956 289.309 416.194 238.83 437.483 197.069 415.387 154.414" {...svg.tone2}></polygon>
        </svg>
    ),
};

export default function NavBar({ bounds, data }) {
    const isMobile = useMedia(["(max-width: 992px)"], [true], false);
    const [ref, sticky] = useSticky();

    const { top } = useSpring({
        top: sticky ? (isMobile ? `0rem` : `1.5rem`) : `5.5rem`,
        config: config.gentle,
    });

    const styles = {
        position: "fixed",
        top,
        left: isMobile ? `${bounds.left + 0}px` : "auto",
        right: isMobile ? `${bounds.left + 0}px` : `${bounds.right - bounds.width}px`,
    };

    return (
        <animated.nav {...ref} className="text-center" style={styles}>
            <ul>
                <li>
                    <a href="#about" title={data.about.label}>
                        <img className="rounded-circle avatar" src={data.about.avatar} alt={data.about.shortName} />
                    </a>
                </li>

                {Object.keys(data).map((key, idx) => {
                    if (!icon.hasOwnProperty(key) || data[key].items.length <= 0) return "";
                    return (
                        <li key={idx} className="">
                            <a href={`#${key}`} title={data[key].label}>
                                {icon[key.toLowerCase()]}
                            </a>
                        </li>
                    );
                })}

                <li className="">
                    <a href="#contact" title="Contact">
                        {icon.contact}
                    </a>
                </li>
            </ul>
        </animated.nav>
    );
}
